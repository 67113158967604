import Naman from "../../../assets/team/Naman Goel.jpeg";
import kavya from "../../../assets/team/Kavya Tyagi.jpg";
import parth from "../../../assets/team/Parth Singhal.jpg";
import harshit from "../../../assets/team/Harshit Singh Mahara.jpg";
import Tanisha from "../../../assets/team/tanisha.jpeg";
import { parsePath } from "react-router-dom";

export const teamExec = [
  {
    name: "Mr. Naman Goel",
    
    img: Naman,
  },
  {
    name: "Mr. Parth Singhal",
    
    img: parth,
  },
  {
    name: "Ms. Tanisha Bhatt",
    
    img: Tanisha,
  },
  {
    name: "Mr. Harshit Singh Mahara",
    
    img: harshit,
  },

  {
    name: "Ms. Kavya Tyagi",
    
    img: kavya,
  },
];
