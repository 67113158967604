import vivek from "../../../assets/team/vivek.jpeg";
import ish from "../../../assets/team/ish.jpg";
import khushi from "../../../assets/team/kushi.jpeg";
import mishita from "../../../assets/team/Mishita_Saxena.jpg";
import faraz from "../../../assets/team/Faraz Hafeez.jpg";
import tanya from "../../../assets/team/Tanya.jpg";
import abhishek from "../../../assets/team/Abhishek Yadav.jpg";
import aryan from "../../../assets/team/aryan garg.jpeg";
import sakshi from "../../../assets/team/sakshi.jpg";
import harsh from "../../../assets/team/Harsh Jadoun.jpg";
import vrihsa from "../../../assets/team/vrisha.jpg";
import divyansh from "../../../assets/team/Divyansh Agarwal.jpeg";




export const teamLeads = [
    // Technical Operations
    {        
        name: 'Mr. Vivek Kumar Tiwari',
        title: 'Technical Operations',
        img: vivek 
    },
    {        
        name: 'Mr. Ish Purwar',
        title: 'Technical Operations',
        img: ish
    },
    // Resource Management & Activities
    {        
        name: 'Ms. Khushi Chaudhary',
        title: 'Resource Management & Activities',
        img: khushi
    },
{        
        name: 'Ms. Mishita Saxena',
        title: 'Resource Management & Activities',
        img: mishita
    },
{        
        name: 'Mr. Faraz Hafeez',
        title: 'Resource Management & Activities',
        img: faraz
    },
    // ? Social Media & Marketing
{        
        name: 'Ms. Tanya Srivastava',
        title: 'Social Media & Marketing',
        img: tanya
    },
{        
        name: 'Mr. Abhishek Yadav',
        title: 'Social Media & Marketing',
        img: abhishek
    },
{        
        name: 'Mr. Aryan Garg',
        title: 'Social Media & Marketing',
        img: aryan
    },
    //? Head Mentor-Mentee & Outreach
{        
        name: 'Ms. Sakshi Sharma',
        title: 'Mentor-Mentee & Outreach',
        img: sakshi
    },
{        
        name: 'Mr. Harsh Jadoun',
        title: 'Mentor-Mentee & Outreach',
        img: harsh
    },
    // ? Head of Mock Interview & Technical Program
{        
        name: 'Ms. Vrisha Garg',
        title: 'Mock Interview & Technical Program',
        img: vrihsa
    },
{        
        name: 'Mr. Divyansh Agarwal',
        title: 'Mock Interview & Technical Program',
        img: divyansh
    }
]
