import bhoomi from "../../../assets/team/bhoomi.jpg";
import saurabh from "../../../assets/team/saurabhsrivastava.jpg";
import darshika from "../../../assets/team/darshika.jpeg";
import aryan from "../../../assets/team/aryanporwal.jpg";
import anshika from "../../../assets/team/anshikach.JPG";
import poras from "../../../assets/team/poras.jpeg";
import amar from "../../../assets/team/amar.jpg";
import keshav from "../../../assets/team/keshavjha.jpeg";
import injila from "../../../assets/team/injila.jpg";
import ayush from "../../../assets/team/ayush.jpeg";
import shrey from "../../../assets/team/shreyvashistha.jpg";
import gaurav from "../../../assets/team/gauravgulati.jpeg";
import ayushT from "../../../assets/team/Ayush Tyagi.jpg";

export const mentors = [
  {
    name: "Mr.Aryan Porwal",
    img: aryan,
  },
  {
    name: "Mr.Ayush Tyagi",
    img: ayushT,
  },
  {
    name: "Ms.Darshika Gupta",
    img: darshika,
  },
  {
    name: "Mr.Poras Saini",
    img: poras,
  },
  {
    name: "Ms.Anshika Chauhan",
    img: anshika,
  },
  {
    name: "Mr.Amar Jyoti",
    img: amar,
  },
  {
    name: "Ms.Bhoomi",
    img: bhoomi,
  },
  {
    name: "Mr.Saurabh Srivastava",
    img: saurabh,
  },
  {
    name: "Mr.Keshav Jha",
    img: keshav,
  },
  {
    name: "Ms.Injila Ali",
    img: injila,
  },
  {
    name: "Mr.Gaurav Gulati",
    img: gaurav,
  },
  {
    name: "Mr.Shrey Vashistha",
    img: shrey,
  },

  {
    name: "Mr.Ayush Malik",
    img: ayush,
  },
];
