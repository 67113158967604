export const teamWorking = [
  {
    //Technical Operations
    name: "Mr. Ayush Kumar Gupta",
    title: "Technical Operation	",
    img: "",
  },
  {
    name: "Mr. Ayush Mishra",
    title: "Technical Operation	",
    img: "",
  },

  {
    name: "Ms. Kanishka Varshney",
    title: "Technical Operation	",
    img: "",
  },
  {
    name: "Mr. Mayank Srivastava",
    title: "Technical Operation	",
    img: "",
  },
  {
    name: "Mr. Priyank Aggarwal",
    title: "Technical Operation	",
    img: "",
  },
  {
    name: "Ms. Shruti Mohanty",
    title: "Technical Operation	",
    img: "",
  },
  {
    name: "Mr. Vijit Verma",
    title: "Technical Operation	",
    img: "",
  },
  //Social Media and Marketing
  {
    name: "Mr. Dev Teotia",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Mr. Divyam Kumar",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Ms. Ishanvi Chauhan",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Ms. Prajakta Singhal",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Mr. Saurabh Agrawal",
    title: "Social Media & Marketing",
    img: "",
  },
  {
    name: "Mr. Utkarsh Sharma",
    title: "Social Media & Marketing",
    img: "",
  },
  //Menter-Mentee & Outreach
  {
    name: "Ms. Anshika Verma",
    title: "Mentor-Mentee & Outreach",
    img: "",
  },
  {
    name: "Mr. Devansh Bajaj",
    title: "Mentor-Mentee & Outreach",
    img: "",
  },
  {
    name: "Ms. Jiya",
    title: "Mentor-Mentee & Outreach",
    img: "",
  },
  {
    name: "Mr. Kartik Kaushik",
    title: "Mentor-Mentee & Outreach",
    img: "",
  },
  {
    name: "Mr. Sarthak Tyagi",
    title: "Mentor-Mentee & Outreach",
    img: "",
  },
  {
    name: "Ms. Tanishka Singh",
    title: "Mentor-Mentee & Outreach",
    img: "",
  },
  //Mock Interview & Technical Program
  {
    name: "Mr. Gopal Aggarwal",
    title: "Mock Interview & Technical Program",
    img: "",
  }, {
    name: "Ms. Nishtha Srivastava",
    title: "Mock Interview & Technical Program",
    img: "",
  }, {
    name: "Mr. Shivansh Rastogi",
    title: "Mock Interview & Technical Program",
    img: "",
  }, {
    name: "Ms. Tashvi Chaudhary",
    title: "Mock Interview & Technical Program",
    img: "",
  },
  //Resource Management & Activities
  {
    name: "Mr. Aradhya Srivastava",
    title: "Resource Management & Activities",
    img: "",
  }, {
    name: "Mr. Bhaskar Singh",
    title: "Resource Management & Activities",
    img: "",
  }, {
    name: "Mr. Harsh Mavi",
    title: "Resource Management & Activities",
    img: "",
  }, {
    name: "Ms. Prachi Singh",
    title: "Resource Management & Activities",
    img: "",
  }, {
    name: "Mr. Satyam Sharma",
    title: "Resource Management & Activities",
    img: "",
  }, {
    name: "Mr. Utkarsh Kumar",
    title: "Resource Management & Activities",
    img: "",
  }, {
    name: "Mr. Vinit Baishkiyar",
    title: "Resource Management & Activities",
    img: "",
  },
];
